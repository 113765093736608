import React from 'react';

const PagesBook = (props) => {
    return (
        <div className="page" data-density="soft">
            <div className="page-content">
                <div>{props.children}</div>
            </div>
      </div>
    );
}

export default PagesBook;