import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axios from "axios";

//
import { useStyles } from "./StyleCursus";
import { notyf, loginConfig } from "../Mails/mails";
import { verificationEmail, verificationNomPrenom } from "../Utilitaires";
import CodePromo from "../Mails/Templates/CodePromo";

const FormCodePromo = ({ provenance, setModalForm }) => {
  const classes = useStyles();
  const [erreurs, setErreurs] = useState([]);
  const [form, setForm] = useState({
    nom: "",
    prenom: "",
    email: "",
    newsletter: false,
    provenance: provenance,
  });
  const [loader, setLoader] = useState(false);

  const toggleFormulaire = (e) => {
    setModalForm((prev) => !prev);
  };

  const onChangeInput = (e) => {
    const target = e.currentTarget;
    let nouvelleValeur = null;
    if (target.id === "newsletterInput") {
      nouvelleValeur = {
        newsletter: target.checked,
      };
    } else {
      nouvelleValeur = {
        [target.name]: target.value,
      };
    }

    setForm((prev) => ({
      ...prev,
      ...nouvelleValeur,
    }));
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    const { nom, prenom, email, newsletter } = form;

    //Vérification des données entrées
    let erreursArray = [];
    if (!verificationNomPrenom(nom)) {
      erreursArray.push("Le format du nom n'est pas valide.");
    }
    if (!verificationNomPrenom(prenom)) {
      erreursArray.push("Le format du prénom n'est pas valide.");
    }
    if (!verificationEmail(email)) {
      erreursArray.push("Le format de l'email n'est pas valide.");
    }

    //Si pas d'erreur -> envoyer code promos par mail + enregistrer le client +/- inscrire newsletter
    if (erreursArray.length === 0) {
      setErreurs(erreursArray);
      setLoader(true);
      //PREPARATION DES ELEMENTS AXIOS///////////
      const url = process.env.REACT_APP_API_URL;
      const CodePromoTemplate = ReactDOMServer.renderToStaticMarkup(
        <CodePromo />
      );
      const emailCodePromo = {
        Messages: [
          {
            From: {
              Email: "contact@eve-education.com",
              Name: "EVE Éducation",
            },
            To: [
              {
                Email: email,
              },
            ],
            Subject:
              "Félicitations ! Vous bénéficiez de notre offre promotionnelle",
            HTMLPart: CodePromoTemplate,
          },
        ],
      };
      const order = {
        data: form,
        codePromo: "OFFRE",
      };
      //AXIOS//////////////////

      axios(loginConfig)
        .then((res) => {
          const token = res.data.token;
          if (token) {
            const headers = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            // Préparer toutes les promesses pour les requêtes asynchrones
            const mailPromise = axios
              .post(`${url}send`, JSON.stringify(emailCodePromo), headers)
              .then((response) => ({ success: true, response }))
              .catch((error) => ({
                success: false,
                error,
                request: "l'envoi du mail",
              }));
            const orderPromise = axios
              .post(`${url}order`, JSON.stringify(order), headers) //revoir l'url order car a changé depuis maj API
              .then((response) => ({ success: true, response }))
              .catch((error) => ({
                success: false,
                error,
                request: "l'enregistrement",
              }));

            const newsletterPromise = newsletter
              ? axios
                  .post(`${url}newsletter`, { email: email }, headers)
                  .then((response) => ({ success: true, response }))
                  .catch((error) => ({
                    //si on la catch pas ici, elle va être catchée par le grand catch
                    success: true, //pour ne pas l'inscrire en tant qu'erreur
                    error,
                    request: "l'inscription à la newsletter",
                  }))
              : Promise.resolve({ skipped: true }); //s'assure que newsletterPromise reste une promesse même si la variable newsletter n'existe pas

            // Exécuter toutes les promesses simultanément et attendre qu'elles soient toutes résolues
            return Promise.all([mailPromise, orderPromise, newsletterPromise]);
          } else {
            throw new Error("Token non disponible");
          }
        })
        .then((results) => {
          setLoader(false);
          // Vérifiez ici le résultat de chaque promesse
          const errors = results.filter((r) => r.success === false);
          if (errors.length > 0) {
            // Traiter les erreurs
            errors.forEach((err) => {
              console.error(err.error.message);
              notyf.error(
                `Une erreur est survenue lors de ${err.request}, veuillez réessayer.`
              );
            });
          } else {
            // Toutes les requêtes ont réussi, on peut rediriger vers la page de remerciements
            window.location.href = "/confirmation-envoi";
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          notyf.error(
            "Une erreur est survenue lors de la connexion au serveur"
          );
        });
    } else {
      //erreurs
      setErreurs(erreursArray);
    }
  };

  return (
    <div className={classes.ModalForm}>
      <Form onSubmit={onHandleSubmit}>
        <i className="fa-solid fa-xmark" onClick={toggleFormulaire}></i>
        <div style={{ marginBottom: "1em" }}>
          Merci de fournir ces informations :
        </div>
        <FormGroup>
          <Label for="nomInput">
            Nom <span style={{ color: "red" }}>*</span>
          </Label>

          <Input
            id="nomInput"
            name="nom"
            value={form.nom}
            onChange={onChangeInput}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="prenomInput">
            Prénom <span style={{ color: "red" }}>*</span>
          </Label>

          <Input
            id="prenomInput"
            name="prenom"
            value={form.prenom}
            onChange={onChangeInput}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="emailInput">
            Email <span style={{ color: "red" }}>*</span>
          </Label>

          <Input
            id="emailInput"
            name="email"
            type="email"
            value={form.email}
            onChange={onChangeInput}
            required
          />
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            id="newsletterInput"
            onChange={onChangeInput}
            checked={form.newsletter ? "checked" : ""}
          />{" "}
          <Label check for="newsletterInput">
            <small>
              Je m'inscris à la newsletter pour être prévenu(e) des promotions
              EVE Éducation
            </small>
          </Label>
        </FormGroup>
        <div
          style={{
            marginBlock: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {erreurs.map((erreur, k) => {
            return <small style={{ color: "red" }}>{erreur}</small>;
          })}
        </div>
        <Button>
          {loader ? "Envoi en cours ..." : "Recevoir le code promo par email"}
        </Button>
      </Form>
    </div>
  );
};

export default FormCodePromo;
