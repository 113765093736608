import React, { useEffect, Fragment /*, useState */ } from "react";
import { PageFlip } from "page-flip";
import PagesBook from "./components/PagesBook";
import PdfBook from "./PdfBook";

import useSound from "use-sound";
import pageSound from "./PAPRHndl_Pages qu on tourne 6 (ID 2213)_LS.mp3";

import useWindowResize from "../../Responsive";
import { _Colors } from "../../../Jss-styles/_variables";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  PagesControls: {
    "& button": {
      backgroundColor: "transparent",
      "&:hover": {
        "& svg": {
          fill: _Colors.darkPink,
        },
      },
    },
    "& .prev": {
      position: "absolute",
      top: "50%",
      left: "10px",
    },
    "& .next": {
      position: "absolute",
      top: "50%",
      right: "10px",
    },
  },
});

const PDFBookFlip = (props) => {
  const classes = useStyles();

  const windowSize = useWindowResize();

  const [play] = useSound(pageSound, { volume: 0.8 });

  //const [currentPage, setCurrentPage] = useState(1);

  const getWidth = () => {
    /*return windowSize.width < 400 ? windowSize.width - 50 : 400;*/
    return getHeight() / Math.sqrt(2);
  };
  const getHeight = () => {
    /*
    return windowSize.width < 400
      ? getWidth() * Math.sqrt(2)
      : 400 * Math.sqrt(2);*/
    let height = windowSize.height * 0.95 - 100;
    const width = height / Math.sqrt(2);
    //la width est calculée par rapport à la height dans un ratio 21 * 29,7 (racine 2)
    // mais si la width est plus grande que la largeur de l'écran, on change la height
    if (width > windowSize.width - 32) {
      height = (windowSize.width - 32) * Math.sqrt(2);
    }
    return height;
  };

  useEffect(() => {
    //console.log("windowsHeight", windowSize.height);
    const pageFlip = new PageFlip(document.getElementById("flipBook"), {
      width: getWidth(), // base page width
      height: getHeight(), // base page height
      //size: "stretch",
      // set threshold values:
      minWidth: getWidth(),
      maxWidth: getWidth(),
      minHeight: getHeight(),
      maxHeight: getHeight(),
      maxShadowOpacity: 0.5, // Half shadow intensity
      showCover: true,
      mobileScrollSupport: true, // disable content scrolling on mobile devices
      usePortrait: true,
      drawShadow: true,
    });

    pageFlip.loadFromHTML(document.querySelectorAll(".page"));
    // pageFlip.loadFromImages(props.pages)

    /*pageFlip.on("changeState", (e) => {
      if (e.data === "flipping" || e.data === "user_fold") {
        play();
      }
    });*/

    /*pageFlip.on("flip", (e) => {
      if(currentPage < e.data){
        setMovement("forward");
      }else{
        setMovement("back");
      }
      setCurrentPage(e.data + 1);

     
    });*/

    document.querySelector(".prev").addEventListener("click", () => {
      pageFlip.flipPrev(); // Turn to the previous page (with animation)
    });

    document.querySelector(".next").addEventListener("click", () => {
      pageFlip.flipNext(); // Turn to the next page (with animation)
    });
    //TODO vérifier
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div
        id="flipBook"
        onClick={() => {
          play();
        }}
        className="flip-book html-book"
        style={{ width: `${getWidth()} !important` }}
      >
        {Array.from(new Array(props.numPages), (el, index) => (
          <PagesBook number={index + 1} key={`page_${index + 1}`}>
            <PdfBook src={props.src} index={index} />
          </PagesBook>
        ))}
        <div className={classes.PagesControls}>
          <button className="prev" type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill={_Colors.darkBlue}
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </button>
          <button className="next" type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill={_Colors.darkBlue}
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default PDFBookFlip;
