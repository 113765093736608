import React from "react";
import {
  CourseChoiceOptions,
  DaysOption,
  GenderOptions,
  Lv2Options,
  NbHoursOptions,
  PaymentOptions,
  StudentLevelOptions,
  SlotsOption,
  Availability,
} from "../../GenericSelect";
import { SelectOptionsInput } from "../../GenericSelect/SelectOptionsInput";

const OtherCursusOptions = ({
  formData,
  handleSelect,
  handleClick,
  config,
  stock,
  isValid,
  productName,
  category,
}) => {
  const {
    genderOpt,
    courses,
    levels,
    nbHours,
    lv2Opt,
    days,
    slotsOpt,
    paymentOpt,
    availability,
    branches,
  } = config;

  //console.log("OtherCursusOptions", formData, config, stock);

  const stockByGender = (genderValue = "") => {
    if (typeof stock[genderValue] === "boolean") {
      return stock[genderValue];
    } else {
      return Boolean(
        stock[genderValue]["morning"] || stock[genderValue]["afternoon"]
      );
    }
  };

  return (
    <>
      {branches !== undefined && (
        <SelectOptionsInput
          handleSelect={handleSelect}
          name="branch"
          value={formData["branch"]}
          required
        >
          <option value="">Choisir le niveau</option>
          {branches.map((branch, key) => (
            <option key={key} value={branch}>
              {branch}
            </option>
          ))}
        </SelectOptionsInput>
      )}
      {genderOpt && (
        <GenderOptions
          value={formData["gender"]}
          handleSelect={handleSelect}
          isOpen={stockByGender}
        />
      )}
      {courses !== undefined && (
        <CourseChoiceOptions
          value={formData["courseChoice"]}
          handleSelect={handleSelect}
          courses={courses}
          category={category}
        />
      )}
      {levels !== undefined && (
        <StudentLevelOptions
          formData={formData}
          productName={productName}
          levels={levels}
          value={formData["studentLevel"]}
          handleSelect={handleSelect}
        />
      )}
      {nbHours && (
        <NbHoursOptions
          value={formData["nbHours"]}
          handleSelect={handleSelect}
        />
      )}
      {lv2Opt && (
        <Lv2Options
          stock={stock}
          formData={formData}
          handleSelect={handleSelect}
        />
      )}
      {days !== undefined && (
        <DaysOption
          opt={days}
          value={formData["days"]}
          handleSelect={handleSelect}
        />
      )}
      {slotsOpt !== undefined && (
        <SlotsOption
          opt={config.slotsOpt}
          value={formData["slots"]}
          handleSelect={handleSelect}
        />
      )}
      {availability !== undefined && (
        <Availability
          checkedBoxes={formData["availability"]}
          handleClick={handleClick}
          isValid={isValid}
        />
      )}
      {paymentOpt !== undefined && (
        <PaymentOptions
          opt={config.paymentOpt}
          value={formData["paymentMethod"]}
          handleSelect={handleSelect}
        />
      )}
    </>
  );
};

OtherCursusOptions.defaultProps = {
  config: {
    genderOpt: false,
    levels: [],
    courses: [],
    nbHours: false,
    paymentOpt: {
      month: null,
      trimester: null,
      year: null,
    },
  },
};

export default OtherCursusOptions;
