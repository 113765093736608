import React from "react";
import { FormGroup, CustomInput, FormFeedback } from "reactstrap";

export const SelectOptionsInput = ({
  handleSelect,
  name,
  value,
  info,
  children,
  required = true,
}) => {
  return (
    <FormGroup>
      <CustomInput
        onChange={handleSelect}
        type="select"
        name={name}
        id={name}
        value={value}
        required={required}
      >
        {children}
      </CustomInput>
      {info}
      <FormFeedback>Ce champ est obligatoire</FormFeedback>
    </FormGroup>
  );
};
