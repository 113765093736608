import { Notyf } from "notyf";

export const notyf = new Notyf({
  position: {
    x: "center",
    y: "bottom",
  },
  duration: 9000,
  dismissible: true,
});

export const loginConfig = {
  method: "post",
  url: `${process.env.REACT_APP_API_URL}login_check`,
  data: JSON.stringify({
    username: process.env.REACT_APP_EVE_EDUCATION_API_USERNAME,
    password: process.env.REACT_APP_EVE_EDUCATION_API_PASSWORD,
  }),
  headers: { "Content-Type": "application/json" },
};
