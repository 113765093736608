import React from "react";
import { _Colors } from "../../../../../Jss-styles/_variables";
import { useStyles } from "../../StyleCursus";

const ChoixPays = ({ togglePays, pays, position = null }) => {
  const classes = useStyles();
  return (
    <div
      style={
        position === "tarifs"
          ? {
              fontSize: "20px",
              lineHeight: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }
          : {}
      }
    >
      {position === "tarifs" ? (
        <small>Quel est votre système scolaire de référence ?</small>
      ) : (
        <small>
          Faites varier les informations en sélectionnant votre système scolaire
          de référence.
        </small>
      )}

      <div className={classes.TogglePays}>
        {" "}
        <div
          onClick={togglePays}
          style={
            pays === "France"
              ? { backgroundColor: _Colors.blue2 }
              : { backgroundColor: "white" }
          }
        >
          Français
        </div>
        <div
          onClick={togglePays}
          style={
            pays === "Belgique"
              ? { backgroundColor: "#fe77c333" }
              : { backgroundColor: "white" }
          }
        >
          Belge
        </div>
      </div>
    </div>
  );
};

export default ChoixPays;
