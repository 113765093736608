import React, { useRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { createUseStyles } from "react-jss";
import { useState, useEffect } from "react";
//import PagesBook from "./components/PagesBook";

const useStyles = createUseStyles({
  // PdfContent:{
  //     width:'100%',
  //     height:'auto',
  //     position:'relative',
  // },
  Document: {
    width: "100%",
    height: "100%",
  },
  DocumentPages: {
    height: "98% !important",
    width: "100%",
    marginBottom: "0.75rem",
    position: "inherit !important",
    "& .react-pdf__Page__svg": {
      height: "98% !important",
      margin: "auto",
      maxWidth: "100% !important",
      "& svg": {
        height: "98% !important",
        maxWidth: "100% !important",
      },
    },
    "& .react-pdf__Page__canvas": {
      height: "98% !important",
      width: "100% !important",
      margin: "auto",
      maxWidth: "100% !important",
      "& canvas": {
        height: "98% !important",
        maxWidth: "100% !important",
      },
    },
  },
  // PagesControls:{
  //     position: 'absolute' ,
  //     bottom: '1.5%',
  //     left: '37.5%',
  //     background: 'white',
  //     boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
  //     borderRadius: '4px',
  //     '& button:first-child':{
  //         borderTopRightRadius: '0',
  //         borderBottomRightRadius: '0',
  //     },
  //     '& button:last-child':{
  //         borderTopLeftRadius: '0',
  //         borderBottomLeftRadius: '0',
  //     },
  //     '& button':{
  //         width: '44px',
  //         height: '44px',
  //         background: 'white',
  //         border: '0',
  //         fontSize: '1em',
  //         borderRadius: '4px',
  //         transition: 'all 0.2s ease'
  //     },
  //     '& button:hover':{
  //         '& svg':{
  //             transform: 'scale(1.5)',
  //             fill:_Colors.darkPink
  //         }
  //     }
  // },
});

const PdfBook = ({ src, index }) => {
  const [contentWidth, setContentWidth] = useState(400);

  const cssProps = { contentWidth };
  const classes = useStyles(cssProps);

  const bookContent = useRef();

  function setWidthListener(width) {
    setContentWidth(width);
  }

  useEffect(() => {
    let contentSize = bookContent.current;
    contentWidth === 400 && setContentWidth(contentSize.clientWidth);

    window.addEventListener(
      "resize",
      setWidthListener(contentSize.clientWidth)
    );
    //console.log(contentSize.clientWidth)
    return () => {
      window.removeEventListener("resize", () => null);
    };
    //TODO vérifier
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ height: "auto", width: "100%" }} ref={bookContent}>
      <Document file={src} className={classes.Document}>
        <Page
          renderMode="canvas"
          className={`${classes.DocumentPages}`}
          pageNumber={index + 1}
        />
      </Document>
    </div>
  );
};

export default PdfBook;
