export const _Colors = {
  blue: "#4bc8e8", //couleur principale
  blue2: "#E7F7FC", //edt + supports
  blue3: "#E7F7FC", //preuve sociale
  purple: "#4d59b0",
  purple2: "#E6E6FA",
  purple3: "#2E347D",

  violet: "#aa39b6",
  violet2: "#F2E0F3",
  violet3: "#F8EFF9",

  pink: "#fe77c3",
  //pink: "#FC5090",
  pink2: "#FFA6D5",
  //pink2: "#FEB1CA",
  pink3: "#DB6091",
  //pink3: "#E675A7",

  marron: "#c38387",
  yellow: "#f8b733",
  grey: "#a2aacc",
  green: "#00ff80",
  orange: "#F56A0B", //#f16306", //"#FEB55F",
  paleGreen: "#98fb98",
  lime: "#0f0",
  veryLightTeal: "#E0F2F1",
  veryLightBlue: "#E1F5FE",
  veryLightDeepPurple: "#D1C4E9",
  veryLightPurple: "#F3E5F5",
  veryLightPink: "#FCE4EC",
  veryLightGreen: "#DCEDC8",
  lightGreen: "#90ee90",
  lightblue: "#b6daee",
  lightpink: "#d292ab",
  darkGreen: "#32cd32",
  darkGrey: "#5a5a5a",
  darkPink: "#e4256e",
  darkBlue: "#3b8be4",
  blueZoom: "#407BFE",
};

export const _BoxShadows = {
  medium: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
  large: "0 1rem 3rem rgba(0, 0, 0, 0.175)",
  blue: "0 0.5rem 1rem rgb(78, 191, 230, 0.15)",
};
