//échéanciers classique
import mensuelMaternelleClassique from "../../../../assets/img/echeanciers/mensuel_maternelle_classique.svg";
import mensuelCPClassique from "../../../../assets/img/echeanciers/mensuel_CP_classique.svg";
import mensuelCM1Classique from "../../../../assets/img/echeanciers/mensuel_CM1_classique.svg";
import mensuel5eClassique from "../../../../assets/img/echeanciers/mensuel_5e_classique.svg";
import mensuel3eClassique from "../../../../assets/img/echeanciers/mensuel_3e_classique.svg";
import mensuel2deClassique from "../../../../assets/img/echeanciers/mensuel_2de_classique.svg";
import mensuel1reClassique from "../../../../assets/img/echeanciers/mensuel_1re_classique.svg";
import mensuelTC from "../../../../assets/img/echeanciers/mensuel_TC.svg";
import trimestrielMaternelleClassique from "../../../../assets/img/echeanciers/trimestriel_maternelle_classique.svg";
import trimestrielCPClassique from "../../../../assets/img/echeanciers/trimestriel_CP_classique.svg";
import trimestrielCM1Classique from "../../../../assets/img/echeanciers/trimestriel_CM1_classique.svg";
import trimestriel5eClassique from "../../../../assets/img/echeanciers/trimestriel_5e_classique.svg";
import trimestriel3eClassique from "../../../../assets/img/echeanciers/trimestriel_3e_classique.svg";
import trimestriel2deClassique from "../../../../assets/img/echeanciers/trimestriel_2de_classique.svg";
import trimestriel1reClassique from "../../../../assets/img/echeanciers/trimestriel_1re_classique.svg";
import trimestrielTC from "../../../../assets/img/echeanciers/trimestriel_TC.svg";
import annuelMaternelleClassique from "../../../../assets/img/echeanciers/annuel_maternelle_classique.svg";
import annuelCPClassique from "../../../../assets/img/echeanciers/annuel_CP_classique.svg";
import annuelCM1Classique from "../../../../assets/img/echeanciers/annuel_CM1_classique.svg";
import annuel5eClassique from "../../../../assets/img/echeanciers/annuel_5e_classique.svg";
import annuel3eClassique from "../../../../assets/img/echeanciers/annuel_3e_classique.svg";
import annuel2deClassique from "../../../../assets/img/echeanciers/annuel_2de_classique.svg";
import annuel1reClassique from "../../../../assets/img/echeanciers/annuel_1re_classique.svg";
import annuelTC from "../../../../assets/img/echeanciers/annuel_TC.svg";

//échéanciers double
import mensuelCPDouble from "../../../../assets/img/echeanciers/mensuel_CP_double.svg";
import mensuelCM1Double from "../../../../assets/img/echeanciers/mensuel_CM1_double.svg";
import mensuel5eDouble from "../../../../assets/img/echeanciers/mensuel_5e_double.svg";
import mensuel3eDouble from "../../../../assets/img/echeanciers/mensuel_3e_double.svg";
import mensuel2deDouble from "../../../../assets/img/echeanciers/mensuel_2de_double.svg";
import mensuel1reDouble from "../../../../assets/img/echeanciers/mensuel_1re_double.svg";
import trimestrielCPDouble from "../../../../assets/img/echeanciers/trimestriel_CP_double.svg";
import trimestrielCM1Double from "../../../../assets/img/echeanciers/trimestriel_CM1_double.svg";
import trimestriel5eDouble from "../../../../assets/img/echeanciers/trimestriel_5e_double.svg";
import trimestriel3eDouble from "../../../../assets/img/echeanciers/trimestriel_3e_double.svg";
import trimestriel2deDouble from "../../../../assets/img/echeanciers/trimestriel_2de_double.svg";
import trimestriel1reDouble from "../../../../assets/img/echeanciers/trimestriel_1re_double.svg";
import annuelCPDouble from "../../../../assets/img/echeanciers/annuel_CP_double.svg";
import annuelCM1Double from "../../../../assets/img/echeanciers/annuel_CM1_double.svg";
import annuel5eDouble from "../../../../assets/img/echeanciers/annuel_5e_double.svg";
import annuel3eDouble from "../../../../assets/img/echeanciers/annuel_3e_double.svg";
import annuel2deDouble from "../../../../assets/img/echeanciers/annuel_2de_double.svg";
import annuel1reDouble from "../../../../assets/img/echeanciers/annuel_1re_double.svg";

//échéanciers bilingue
import mensuelCPBilingue from "../../../../assets/img/echeanciers/mensuel_CP_bilingue.svg";
import mensuelCM1Bilingue from "../../../../assets/img/echeanciers/mensuel_CM1_bilingue.svg";
import mensuel5eBilingue from "../../../../assets/img/echeanciers/mensuel_5e_bilingue.svg";
import mensuel3eBilingue from "../../../../assets/img/echeanciers/mensuel_3e_bilingue.svg";
import mensuel2deBilingue from "../../../../assets/img/echeanciers/mensuel_2de_bilingue.svg";
import mensuel1reBilingue from "../../../../assets/img/echeanciers/mensuel_1re_bilingue.svg";
import trimestrielCPBilingue from "../../../../assets/img/echeanciers/trimestriel_CP_bilingue.svg";
import trimestrielCM1Bilingue from "../../../../assets/img/echeanciers/trimestriel_CM1_bilingue.svg";
import trimestriel5eBilingue from "../../../../assets/img/echeanciers/trimestriel_5e_bilingue.svg";
import trimestriel3eBilingue from "../../../../assets/img/echeanciers/trimestriel_3e_bilingue.svg";
import trimestriel2deBilingue from "../../../../assets/img/echeanciers/trimestriel_2de_bilingue.svg";
import trimestriel1reBilingue from "../../../../assets/img/echeanciers/trimestriel_1re_bilingue.svg";
import annuelCPBilingue from "../../../../assets/img/echeanciers/annuel_CP_bilingue.svg";
import annuelCM1Bilingue from "../../../../assets/img/echeanciers/annuel_CM1_bilingue.svg";
import annuel5eBilingue from "../../../../assets/img/echeanciers/annuel_5e_bilingue.svg";
import annuel3eBilingue from "../../../../assets/img/echeanciers/annuel_3e_bilingue.svg";
import annuel2deBilingue from "../../../../assets/img/echeanciers/annuel_2de_bilingue.svg";
import annuel1reBilingue from "../../../../assets/img/echeanciers/annuel_1re_bilingue.svg";

//échéanciers belge
import mensuel1reBelge from "../../../../assets/img/echeanciers/mensuel_1re_belge.svg";
import mensuel3eBelge from "../../../../assets/img/echeanciers/mensuel_3e_belge.svg";
import mensuel5eBelge from "../../../../assets/img/echeanciers/mensuel_5e_belge.svg";
import trimestriel1reBelge from "../../../../assets/img/echeanciers/trimestriel_1re_belge.svg";
import trimestriel3eBelge from "../../../../assets/img/echeanciers/trimestriel_3e_belge.svg";
import trimestriel5eBelge from "../../../../assets/img/echeanciers/trimestriel_5e_belge.svg";
import annuel1reBelge from "../../../../assets/img/echeanciers/annuel_1re_belge.svg";
import annuel3eBelge from "../../../../assets/img/echeanciers/annuel_3e_belge.svg";
import annuel5eBelge from "../../../../assets/img/echeanciers/annuel_5e_belge.svg";

const echeanciers = {
  classique: {
    mensuel: {
      Maternelle: mensuelMaternelleClassique,
      CP: mensuelCPClassique,
      CM1: mensuelCM1Classique,
      "6eme": mensuelCM1Classique,
      "5eme": mensuel5eClassique,
      "3eme": mensuel3eClassique,
      Seconde: mensuel2deClassique,
      Première: mensuel1reClassique,
    },
    trimestriel: {
      Maternelle: trimestrielMaternelleClassique,
      CP: trimestrielCPClassique,
      CM1: trimestrielCM1Classique,
      "6eme": trimestrielCM1Classique,
      "5eme": trimestriel5eClassique,
      "3eme": trimestriel3eClassique,
      Seconde: trimestriel2deClassique,
      Première: trimestriel1reClassique,
    },
    annuel: {
      Maternelle: annuelMaternelleClassique,
      CP: annuelCPClassique,
      CM1: annuelCM1Classique,
      "6eme": annuelCM1Classique,
      "5eme": annuel5eClassique,
      "3eme": annuel3eClassique,
      Seconde: annuel2deClassique,
      Première: annuel1reClassique,
    },
  },
  double: {
    mensuel: {
      CP: mensuelCPDouble,
      CM1: mensuelCM1Double,
      "6eme": mensuelCM1Double,
      "5eme": mensuel5eDouble,
      "3eme": mensuel3eDouble,
      Seconde: mensuel2deDouble,
      Première: mensuel1reDouble,
    },
    trimestriel: {
      CP: trimestrielCPDouble,
      CM1: trimestrielCM1Double,
      "6eme": trimestrielCM1Double,
      "5eme": trimestriel5eDouble,
      "3eme": trimestriel3eDouble,
      Seconde: trimestriel2deDouble,
      Première: trimestriel1reDouble,
    },
    annuel: {
      CP: annuelCPDouble,
      CM1: annuelCM1Double,
      "6eme": annuelCM1Double,
      "5eme": annuel5eDouble,
      "3eme": annuel3eDouble,
      Seconde: annuel2deDouble,
      Première: annuel1reDouble,
    },
  },

  bilingue: {
    mensuel: {
      CP: mensuelCPBilingue,
      CM1: mensuelCM1Bilingue,
      "6eme": mensuelCM1Bilingue,
      "5eme": mensuel5eBilingue,
      "3eme": mensuel3eBilingue,
      Seconde: mensuel2deBilingue,
      Première: mensuel1reBilingue,
    },
    trimestriel: {
      CP: trimestrielCPBilingue,
      CM1: trimestrielCM1Bilingue,
      "6eme": trimestrielCM1Bilingue,
      "5eme": trimestriel5eBilingue,
      "3eme": trimestriel3eBilingue,
      Seconde: trimestriel2deBilingue,
      Première: trimestriel1reBilingue,
    },
    annuel: {
      CP: annuelCPBilingue,
      CM1: annuelCM1Bilingue,
      "6eme": annuelCM1Bilingue,
      "5eme": annuel5eBilingue,
      "3eme": annuel3eBilingue,
      Seconde: annuel2deBilingue,
      Première: annuel1reBilingue,
    },
  },

  belge: {
    mensuel: {
      "1re année": mensuel1reBelge,
      "3e année": mensuel3eBelge,
      "5e année": mensuel5eBelge,
    },
    trimestriel: {
      "1re année": trimestriel1reBelge,
      "3e année": trimestriel3eBelge,
      "5e année": trimestriel5eBelge,
    },
    annuel: {
      "1re année": annuel1reBelge,
      "3e année": annuel3eBelge,
      "5e année": annuel5eBelge,
    },
  },
  carte: {
    mensuel: {
      "Tronc Commun": mensuelTC,
    },
    trimestriel: {
      "Tronc Commun": trimestrielTC,
    },
    annuel: {
      "Tronc Commun": annuelTC,
    },
  },
};

export const FournirEcheancier = (cursus, paiement, classe) => {
  return echeanciers[`${cursus}`][`${paiement}`][`${classe}`];
};
