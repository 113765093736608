import React from "react";
import ReactDOM from "react-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { RecoilRoot } from "recoil";
import "./styles/base.scss";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "notyf/notyf.min.css";
//import ScrollToTop from "./assets/ScrollToTop";
import dotenv from "dotenv";
import ErrorBoundary from "./components/WebSiteApp/Utilitaires/ErrorBoundary";

dotenv.config(); //charge les variables d'environnement

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_KEY,
  currency: "EUR",
  intent: "capture",
};

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <PayPalScriptProvider options={initialOptions}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </PayPalScriptProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
