import React, { useState } from "react";
import { Form, Button } from "reactstrap";
import { createUseStyles } from "react-jss";
import { _BoxShadows, _Colors } from "../../../../Jss-styles/_variables";
import CompletCursusOptions from "./ProductOptions/OptionsTemplate/ClassicAndDoubleCursus";
import OtherCursusOptions from "./ProductOptions/OptionsTemplate/OtherCursus";

const useStyles = createUseStyles({
  Form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem",

    "& .form-group": {
      width: "100%",
      maxWidth: "30rem",
      marginBottom: "1.5rem !important",
      "& .custom-select": {
        border: `2px solid ${_Colors.blue}`,
        borderRadius: "10px ",
        "&:focus": {
          borderColor: _Colors.pink,
          boxShadow: "none",
        },
      },
    },
    "& button": {
      backgroundColor: _Colors.purple,
      width: "50%",
      minWidth: "220px",
      marginTop: "2rem",
      transition: "all 200ms ease-in-out ",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: _BoxShadows.medium,
        backgroundColor: _Colors.darkPink,
      },
    },
  },
  "@media (max-width: 390px)": {
    Form: {
      margin: "0px",
    },
  },
});

const ProductOptionForm = ({ product, addProductToList }) => {
  const classes = useStyles();

  const cursus = product.cursus;
  const productName = product.name.toLowerCase();
  const category = product.category;

  const data = {
    slots: "",
    gender: "",
    branch: "",
    firstOption: "",
    secondOption: "",
    thirdOption: "",
    lv2: "",
    lv3: "",
    paymentMethod: "",
    nbHours: "",
    courseChoice: "",
    studentLevel: "",
    availability: {},
    days: "",
  };
  const [formData, setFormData] = useState(data);
  const [isValid, setValid] = useState(true);

  const handleSelect = (e) => {
    let value = e.target.value;
    value !== "" && (e.target.style.borderColor = "#fe77c3");
    setFormData({ ...formData, [e.target.name]: value });
    //console.log("formDataSelect", value, formData);
  };

  //déclenchée au cochement d'une checkbox
  const handleClick = (e) => {
    //console.log(e.target, e.target.checked); //--> true/false
    const target = e.target;
    let savedForm = formData;
    let dayArray = savedForm["availability"][target.name];
    if (target.checked) {
      dayArray !== undefined
        ? dayArray.push(target.value)
        : (savedForm["availability"][target.name] = [target.value]);
      setFormData(savedForm);
    } else {
      //retirer la clé si sa valeur (tableau) ne contenait qu'une donnée sinon retirer seulement cette donnée du tableau
      if (dayArray.length === 1) {
        delete savedForm["availability"][target.name];
      } else {
        let newArray = savedForm["availability"][target.name].filter(function (
          f
        ) {
          return f !== target.value;
        });
        savedForm["availability"][target.name] = newArray;
      }
      setFormData(savedForm);
    }
    //console.log("formDataClick", formData);
  };

  //submit of forms and add product choice to the addProductToList method
  const handleSubmit = (e) => {
    e.preventDefault();
    //validation
    if (checkRequiredFields(getInputsConfigForOtherCursus())) {
      //Validation Formulaire cours exclusifs : cohérence entre le nombre d'heures et les diponibilités
      if (category === "exclusif") {
        let slotsNb = 0;
        let days = formData["availability"];
        for (let day in days) {
          slotsNb += days[day].length;
        }
        let hoursNb = parseFloat(formData["nbHours"]);
        if (slotsNb < hoursNb / 2) {
          setValid(false);
        } else {
          addProductToList(formData);
        }
      } else if (isValid) {
        addProductToList(formData);
      }
    } else {
      setValid(false);
    }
  };

  const schoolClasses = [
    "maternelle",
    "cp",
    "ce1",
    "ce2",
    "cm1",
    "cm2",
    "6eme",
    "5eme",
    "4eme",
    "3eme",
    "seconde",
    "première",
    "terminale",
  ];

  //TODO bool : concordance entre config et formData
  const checkRequiredFields = (config) => {
    return true;
  };

  const getInputsConfigForCompletCursus = () => {
    let productIndex = schoolClasses.indexOf(productName);
    if (productIndex === -1) {
      //cad pas dans la liste
      return {
        paymentOpt: product.price,
      };
    }
    if (productIndex === 0) {
      //maternelle
      return {
        branches: product.branches,
        paymentOpt: product.price,
      };
    }
    if (productIndex > 0 && productIndex <= 2) {
      //cp à ce1
      return {
        slotsOpt: cursus === "classique" || cursus === "bilingue",
        paymentOpt: product.price,
      };
    }
    if (productIndex >= 3 && productIndex <= 6) {
      return {
        genderOpt: true,
        slotsOpt: cursus === "classique" || cursus === "bilingue",
        paymentOpt: product.price,
      };
    }
    if (productIndex > 6 && productIndex < 10) {
      return {
        genderOpt: true,
        slotsOpt: cursus === "classique" || cursus === "bilingue",
        lv2Opt: cursus === "classique" || cursus === "bilingue",
        lv3Opt: true,
        paymentOpt: product.price,
      };
    }
    if (productIndex >= 10) {
      //console.log("product", product);
      let options =
        formData["branch"] === "Première générale"
          ? product.generalOptions
          : formData["branch"] === "Terminale générale"
          ? product.generalOptions[0][formData["gender"]]
          : undefined;
      return {
        genderOpt: true,
        slotsOpt: false,
        branches: product.branches,
        options: options,
        lv2Opt: Boolean(cursus === "classique" || cursus === "bilingue"),
        lv3Opt: true,
        paymentOpt: product.price,
      };
    }
  };

  //définition de la config du formulaire pour les autres cursus (quels sont les champs présents et leurs données)
  const getInputsConfigForOtherCursus = () => {
    let config = {};
    // les autres cursus sont au nombre de 3 : carte, ateliers, pec
    if (cursus === "carte") {
      switch (category) {
        case "stage":
          config = {
            genderOpt: true,
            courses: product.coursesChoice,
            levels: product.levels,
            //paymentOpt: product.price,
          };
          break;
        case "tronc":
          config = {
            branches: product.branches,
            genderOpt: true,
            lv2Opt: true,
            paymentOpt: product.price,
          };
          break;
        case "pole":
          config = {
            paymentOpt: product.price,
          };
          break;
        case "reine":
          config = {
            courses: product.coursesChoice,
            paymentOpt: product.price,
          };
          break;
        case "exclusif":
          config = {
            courses: product.coursesChoice,
            levels: product.levels,
            paymentOpt: product.price,
            nbHours: true,
            availability: true,
          };
          break;

        default:
          break;
      }
    } else if (cursus === "atelier") {
      if (product.name === "Ateliers de langues") {
        config.courses = product.coursesChoice;
        config.levels = product.levels;
      }
      config.days = product.days;
      product.name === "Ateliers de langues" &&
        (config.slotsOpt = product.slots);
      config.paymentOpt = product.price;
    } else if (cursus === "pec") {
      config.paymentOpt = product.price;
      config.levels = product.levels;
    }

    return config;
  };

  // const getAddInscriptionButton = () => {

  //     let isCompleted = completedClass.find(cc => cursus === cc.cursus && (productName.toLowerCase() === cc.name || category === cc.name))

  //     return isCompleted ?  null  : <Button> {cursus !== 'pec' ? "Ajouter l'inscription" : "Ajouter le pack"} </Button>
  // }

  const lesCursus = ["classique", "double", "bilingue", "belge"];

  return product.stock.isOpen === false ? (
    <h3 style={{ textAlign: "center", color: _Colors.purple }}>
      Les classes de {product.name} sont complètes
    </h3>
  ) : (
    <Form onSubmit={handleSubmit} className={classes.Form}>
      {lesCursus.includes(product.cursus) ? (
        <CompletCursusOptions
          formData={formData}
          handleSelect={handleSelect}
          config={getInputsConfigForCompletCursus()}
          stock={product.stock}
          cursus={product.cursus}
          classe={product.name}
        />
      ) : (
        <OtherCursusOptions
          productName={product.name}
          formData={formData}
          handleSelect={handleSelect}
          handleClick={handleClick}
          config={getInputsConfigForOtherCursus()}
          stock={product.stock}
          isValid={isValid}
          category={product.category}
        />
      )}
      <Button disabled={!product.stock.isOpen}>
        {/*{" "}
        {cursus !== "pec" ? "Ajouter l'inscription" : "Ajouter le pack"}{" "}*/}
        Ajouter au panier
      </Button>
    </Form>
  );
};

export default ProductOptionForm;
