import React, { useEffect, useState } from "react";
import { useStyles } from "../StyleCursus";
import useWindowResize from "../../../Responsive";
import { _Colors } from "../../../../Jss-styles/_variables";
import ModalBook from "../../Supports/components/ModalBook";
import Schedules from "../Schedules/Schedules";
import { schedulesConfig } from "../Schedules/schedules.config";
import FormCodePromo from "../FormCodePromo";
import ChoixPays from "../Bilingue/composants/ChoixPays";

//images
import banniere from "../../../../assets/img/cursus/double-cursus/banniere-200h.webp";
import puce from "../../../../assets/img/puce.svg";
import offreCC from "../../../../assets/img/cursus/offre-cursus-classique.png";
import offreCBI from "../../../../assets/img/cursus/offre-cursus-bilingue.png";
import offreCBE from "../../../../assets/img/cursus/offre-cursus-belge.png";
import clipartGarantie from "../../../../assets/img/clipart-garantie.png";
import clock from "../../../../assets/img/cursus/clock.webp";
import avatar from "../../../../assets/img/avatar-classe.svg";
import PricingCards from "../../Prices/components/PricingCards";
import lettreArabe from "../../../../assets/img/cursus/double-cursus/lettre_alphabet.png";
import lettresSortentCrayon from "../../../../assets/img/cursus/double-cursus/crayon_arabe.jpeg";
import lectureGarcon from "../../../../assets/img/cursus/double-cursus/primaire/lecture_garcon.jpeg";
import arabe from "../../../../assets/img/cursus/double-cursus/primaire/vocabulaire_arabe.jpeg";
import lectureLettres from "../../../../assets/img/cursus/double-cursus/primaire/lecture_arabe.jpeg";
import ordiFille from "../../../../assets/img/cursus/double-cursus/primaire/ordi.jpeg";

//supports
import supportArabeBleu from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_bleu.pdf";
import supportArabeRouge from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_rouge.pdf";
import supportArabeVert from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_vert.pdf";
import supportArabeTurquoise from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_turquoise.pdf";
import supportFiqhJaune from "../../../../assets/pdf/supports/double-cursus/fiqh/fiqh_niveau_jaune.pdf";
import supportTajwidJaune from "../../../../assets/pdf/supports/double-cursus/tajwid/tajwid_niveau_jaune.pdf";
import supportTajwidMarron from "../../../../assets/pdf/supports/double-cursus/tajwid/tajwid_niveau_marron.pdf";
import supportTajwidTurquoise from "../../../../assets/pdf/supports/double-cursus/tajwid/tajwid_niveau_turquoise.pdf";
import supportCroyanceBleu from "../../../../assets/pdf/supports/double-cursus/croyance/croyance_niveau_bleu.pdf";
import supportCroyanceVert from "../../../../assets/pdf/supports/double-cursus/croyance/croyance_niveau_vert.pdf";
import supportCroyanceMarron from "../../../../assets/pdf/supports/double-cursus/croyance/croyance_niveau_marron.pdf";

const PrimaireDouble2024 = ({ setSelectedNavItem, niveau }) => {
  const classes = useStyles();
  const { width } = useWindowResize();
  const [appareil, setAppareil] = useState();
  const [modalForm, setModalForm] = useState(false);
  const [pays, setPays] = useState("France");

  useEffect(() => {
    setSelectedNavItem(1);
    // eslint-disable-next-line
  }, []);

  const toggleFormulaire = () => {
    setModalForm((prev) => !prev);
  };

  const togglePays = (e) => {
    const systeme = e.currentTarget.innerText;
    if (systeme === "Français") {
      setPays("France");
    }
    if (systeme === "Belge") {
      setPays("Belgique");
    }
  };

  const responsiveWidth = {
    video: {
      PC: 656,
      Mobile: width * 0.9,
      Tablette: width * 0.9,
    },
    avatar: {
      PC: 48,
      Mobile: 36,
      Tablette: 36,
    },
    horloge: {
      PC: 32,
      Mobile: 28,
      Tablette: 28,
    },
    illustration: {
      PC: 400,
      Mobile: width * 0.9,
      Tablette: width * 0.63,
    },
  };
  const responsiveHeight = {
    video: {
      PC: 369,
      Mobile: (width * 0.9 * 9) / 16,
      Tablette: (width * 0.9 * 9) / 16,
    },
    avatar: {
      PC: 48,
      Mobile: 36,
      Tablette: 36,
    },
    horloge: {
      PC: 32,
      Mobile: 28,
      Tablette: 28,
    },
  };

  const format = () => {
    if (width >= 1366) {
      return "PC";
    } else if (width > 600) {
      return "Tablette";
    } else {
      return "Mobile";
    }
  };

  useEffect(() => {
    setAppareil(format());
    // eslint-disable-next-line
  }, [width]);

  const levels = ["CP", "CE1", "CE2", "CM1", "CM2"];
  const [level, setLevel] = useState(niveau ? niveau : levels[0]);
  const matieres = ["Arabe", "Croyance", "Fiqh", "Tajwid"];
  const [matiere, setMatiere] = useState(matieres[0]);
  const edtBtnWidth = width <= 1624 ? "500px" : "70%";
  const supports = {
    Arabe: [
      {
        title: "NIV. BLEU",
        src: supportArabeBleu,
        numPages: 20,
      },
      {
        title: "NIV. VERT",
        src: supportArabeVert,
        numPages: 10,
      },
      {
        title: "NIV. ROUGE",
        src: supportArabeRouge,
        numPages: 13,
      },
      {
        title: "NIV. TURQUOISE",
        src: supportArabeTurquoise,
        numPages: 26,
      },
    ],
    Croyance: [
      {
        title: "NIV. BLEU",
        src: supportCroyanceBleu,
        numPages: 15,
      },
      {
        title: "NIV. VERT",
        src: supportCroyanceVert,
        numPages: 10,
      },
      {
        title: "NIV. MARRON",
        src: supportCroyanceMarron,
        numPages: 20,
      },
    ],
    Fiqh: [
      {
        title: "NIV. JAUNE",
        src: supportFiqhJaune,
        numPages: 17,
      },
    ],
    Tajwid: [
      {
        title: "NIV. JAUNE",
        src: supportTajwidJaune,
        numPages: 13,
      },
      {
        title: "NIV. MARRON",
        src: supportTajwidMarron,
        numPages: 17,
      },
      {
        title: "NIV. TURQUOISE",
        src: supportTajwidTurquoise,
        numPages: 7,
      },
    ],
  };
  const infosLevels = {
    CP: {
      abrev: "CP",
      heures: "15 h",
      studentNb: "10",
      edt: schedulesConfig.primaryDC.CP,
      //supports: supports.Primaire,
    },
    CE1: {
      abrev: "CE1",
      heures: "15 h",
      studentNb: "10",

      edt: schedulesConfig.primaryDC.CE1,
      //supports: supports.Primaire,
    },
    CE2: {
      abrev: "CE2",
      heures: "21 h",
      studentNb: "10",

      edt: schedulesConfig.primaryDC.CE2,
      //supports: supports.Primaire,
    },
    CM1: {
      nom: "CM1",
      abrev: "CM1",
      heures: "22 h",
      studentNb: "10",

      edt: schedulesConfig.primaryDC.CM1,
      //supports: supports.Primaire,
    },
    CM2: {
      abrev: "CM2",
      heures: "22 h",
      studentNb: "10",

      edt: schedulesConfig.primaryDC.CM2,
      //supports: supports.Primaire,
    },
  };

  return (
    <div className={classes.PageCursus}>
      {/**banniere */}
      <div
        className={classes.Banniere}
        style={{ backgroundColor: _Colors.purple }}
      >
        <img src={banniere} alt="banniere double cursus" />
      </div>
      <div className={classes.PageWrapper}>
        {/** titre */}
        <h1 className={classes.H1}>
          <div>DOUBLE CURSUS PRIMAIRE</div>
          <div>
            Le cursus classique combiné à l'apprentissage quotidien de l'arabe
            et de la religion
          </div>
        </h1>

        {/** vidéo + promesse */}
        <div className={classes.VideoEtPromesse}>
          <iframe
            width={responsiveWidth.video[`${appareil}`]}
            height={responsiveHeight.video[`${appareil}`]}
            src="https://www.youtube.com/embed/kU7XHH466KA?autoplay=1&mute=1&loop=1&playlist=kU7XHH466KA"
            title="Le Double Cursus Eve Éducation"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          {/**TPE3CQvWN2E */}
          <div className={classes.Promesse}>
            <div className={classes.TxtPromesse}>
              Une harmonie parfaite entre le système français et l'authenticité
              saoudienne pour un apprentissage enrichi de l'arabe et de l'islam
              {/*
              L'excellence française et la richesse saoudienne sont alliées pour
              offrir à nos enfants une instruction de premier choix dans les
              matières classiques, en langue arabe et en sciences religieuses.*/}
            </div>
            <a href="#tarifs-primaire-double">
              <button className={classes.BtnPromesseDouble}>
                J'inscris mon enfant
              </button>
            </a>
          </div>
        </div>

        {/** accroche */}
        <div
          className={classes.Accroche}
          style={{ backgroundColor: `${_Colors.purple2}` }}
        >
          {/*<strong>
            Optez pour un enseignement de qualité qui respecte vos valeurs
            {appareil === "PC" ? <br /> : " "}
            et accorde de l'attention aux capacités de votre enfant comme à ses
            difficultés !
          </strong>*/}
          <strong>
            Exceller dans ses études et vivre sa foi pleinement dans un
            environnement bienveillant
          </strong>
        </div>

        <div className={classes.InformationsCursus}>
          {/** COLONNE GAUCHE : emploi du temps */}
          <div className={classes.ColonnesGauchesContainer}>
            <div
              className={classes.ColonneGauche}
              style={{ backgroundColor: _Colors.purple2 }}
            >
              {/*Emplois du temps + supports*/}
              <div>
                <h2 className={classes.H2}>Emploi du temps {level}</h2>
                <div className={classes.Indications}>
                  <div className={classes.Indication}>
                    <img
                      src={avatar}
                      alt="clipart avatar"
                      width={responsiveWidth.avatar[`${appareil}`]}
                      height={responsiveHeight.avatar[`${appareil}`]}
                    ></img>
                      Classe : {infosLevels[level]["studentNb"]} élèves
                  </div>
                  <div className={classes.Indication}>
                    <img
                      src={clock}
                      alt="clipart heure"
                      width={responsiveWidth.horloge[`${appareil}`]}
                      height={responsiveWidth.horloge[`${appareil}`]}
                    ></img>
                      Volume horaire : {infosLevels[level]["heures"]} / semaine
                  </div>
                </div>
                <p>
                  <strong>Les cours ont lieu le matin</strong>{" "}
                </p>
                <p style={{ fontSize: "1rem", lineHeight: "1.8rem" }}>
                  Notre emploi du temps favorise une meilleure organisation
                  familiale et facilite l'intégration d'activités éducatives ou
                  sportives extrascolaires.
                </p>

                <div className={classes.LevelsBar}>
                  {levels.map((niveau, key) => {
                    return (
                      <div
                        key={key}
                        className={classes.LevelBtn}
                        style={
                          niveau === level
                            ? { backgroundColor: _Colors.violet2 }
                            : { backgroundColor: "white" }
                        }
                        onClick={() => setLevel(niveau)}
                      >
                        {niveau}
                      </div>
                    );
                  })}
                </div>
                <p style={{ fontSize: "1rem", lineHeight: "1.8rem" }}>
                  Choisissez la classe pour faire varier les informations
                </p>
                <div className={classes.FlexEdtSupports}>
                  <div className={classes.Edt}>
                    <Schedules
                      schedules={infosLevels[level].edt}
                      btnWidth={edtBtnWidth}
                    />
                    <small style={{ fontSize: "1rem", lineHeight: "1.8rem" }}>
                      Cliquer sur l'emploi du temps pour agrandir
                    </small>
                  </div>

                  <div className={classes.Supports}>
                    <h2 className={classes.H2} style={{ lineHeight: "1.7" }}>
                      Aperçu de nos supports
                    </h2>
                    <div className={classes.LevelsBar}>
                      {matieres.map((mat, key) => {
                        return (
                          <div
                            key={key}
                            className={classes.LevelBtn}
                            style={
                              mat === matiere
                                ? { backgroundColor: _Colors.violet2 }
                                : { backgroundColor: "white" }
                            }
                            onClick={() => setMatiere(mat)}
                          >
                            {mat}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {supports[matiere].map((support, key) => {
                        return (
                          <ModalBook
                            key={key}
                            title={support.title}
                            src={support.src}
                            numPages={support.numPages}
                            backgroundColor={_Colors.purple}
                            style={classes.BtnSupports}
                          />
                        );
                      })}
                    </div>
                    <a
                      href="/cursus-classique/primaire"
                      style={{ marginTop: "1em" }}
                    >
                      Voir les supports des matières classiques
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {appareil === "PC" && (
              <div className={classes.ColonneGauche2}>
                {/*photos*/}
                <img src={arabe} alt="carte vocabulaire arabe"></img>
                <img
                  src={ordiFille}
                  alt="petite fille musulmane cours en ligne"
                ></img>
                <img src={lectureGarcon} alt="petit garçon musulman lit"></img>
                <img
                  src={lettresSortentCrayon}
                  alt="lettres arabes sortent d'un crayon"
                ></img>
              </div>
            )}
          </div>

          {/*COLONNE DROITE */}
          <div className={classes.ColonneDroite}>
            {/** présentation cursus */}
            <h2 className={classes.H2}>Qu'est-ce que le Double cursus ?</h2>
            <p>
              C’est une richesse linguistique et religieuse qui offre à votre
              enfant{" "}
              <strong>
                la maîtrise de la langue du Coran (la langue arabe), la
                compréhension de ses mots (le tafsir) et sa récitation tout en
                respectant les règles de tadjwid
              </strong>
              .
            </p>
            <p>
              Ce cursus offre également à votre enfant{" "}
              <strong>
                l'apprentissage et la bonne compréhension de sa religion
              </strong>{" "}
              selon la sounnah du Prophète{" "}
              <span style={{ fontSize: "1.5rem" }}>&#65018;</span> et la
              compréhension des pieux prédécesseurs <span>رضي الله عنهم</span>.
            </p>
            <p>
              L’acquis de toute cette richesse se base sur{" "}
              <strong>
                des méthodologies ludiques, créatives, structurées et amusantes
              </strong>{" "}
              tout en respectant l’âge de votre enfant.
            </p>
            <p>
              Le Double cursus est une véritable opportunité pour vos enfants !
            </p>

            <div className={classes.PetiteAccroche}>
              <strong>
                Ensemble vers l'excellence éducative et une pratique religieuse
                authentique
              </strong>
            </div>
            <img
              className={classes.Illustration}
              src={lectureLettres}
              width={responsiveWidth.illustration[`${appareil}`]}
              alt="lecture lettres arabes"
            ></img>
            {/**PROMO 
            <button
              className={classes.BtnPromoDouble}
              onClick={toggleFormulaire}
              style={{ marginBlock: "2em" }}
            >
              Je veux obtenir le code promo
            </button>*/}
            {/** objectifs  */}
            <h2 className={classes.H2}>
              Quels sont les objectifs du Double cursus en primaire ?
            </h2>
            <ul className={classes.Liste}>
              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>La maîtrise de la langue arabe : parlé et écrit</div>
              </li>

              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>La mémorisation du Coran avec les règles de tadjwid</div>
              </li>
              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>
                  La compréhension du sens des sourates acquises : tafsir
                </div>
              </li>
              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>
                  L'apprentissage de la science religieuse : aqidah, fiqh et
                  adhkar
                </div>
              </li>
            </ul>

            {/** programme  */}
            <h2 className={classes.H2} style={{ marginTop: "3em" }}>
              Quel est le programme du Double cursus en primaire ?
            </h2>

            <h3 className={classes.H3} style={{ marginTop: "28px" }}>
              <img src={lettreArabe} alt="lettre arabe"></img> Le programme de
              langue arabe
            </h3>
            <p>
              Il est élaboré sous la surveillance de notre équipe pédagogique
              par des enseignants diplômés et expérimentés dans ce domaine.
              C'est un programme adapté à nos élèves avec{" "}
              <strong>
                des méthodes d’apprentissage ludiques et créatives
              </strong>{" "}
              au sein des classes, que vous retrouvez dans nos propres supports
              pour chaque niveau.
            </p>
            <h3 className={classes.H3} style={{ marginTop: "28px" }}>
              <span role="img" aria-label="livre">
                📖
              </span>{" "}
              Le programme de Coran
            </h3>
            <p>
              Il comporte <strong>la mémorisation</strong> (selon plusieurs
              récitations - riwaya : Hafs, Warch, Qaloun),{" "}
              <strong>la compréhension et l’étude des regles de tadjwid</strong>
              .
            </p>
            <p>
              Nous proposons un programme complet du niveau débutant avec
              l'apprentissage de sourate an-Nass, jusqu'au lycée avec
              l'apprentissage de sourate al-Baqarah et du poème de{" "}
              <i>Tuhfat al-atfal</i> en tadjwid.
            </p>
            <h3 className={classes.H3} style={{ marginTop: "28px" }}>
              <span role="img" aria-label="mosquée">
                🕌
              </span>{" "}
              Le programme de sciences religieuses
            </h3>
            <p>
              Il est également élaboré sous la surveillance de notre équipe
              pédagogique par des enseignants diplômés, en se basant sur{" "}
              <strong>
                le programme de l'Éducation nationale de l'Arabie Saoudite
              </strong>
              , élaboré et validé par le ministère des affaires religieuses
              présidé par Sheikh Salih Al Sheikh.
            </p>
            {/**PROMO 
            <button
              className={classes.BtnPromoDouble}
              onClick={toggleFormulaire}
              style={{ marginBlock: "2em" }}
            >
              Je veux obtenir le code promo
            </button>*/}
            {/** admissions  */}
            <h2 className={classes.H2} style={{ marginTop: "3em" }}>
              Quelles sont les conditions d'admission ?
            </h2>
            <p className={classes.ApresParagraphe}>
              <strong>Tout le monde peut s'inscrire, sans conditions</strong>. À
              partir du CE1, nous évaluerons, après inscription, le niveau de
              français et de mathématiques de votre enfant afin de l'inclure
              dans une classe adaptée à ses résultats. Cette approche sur mesure
              nous permet de répondre aux besoins et capacités uniques de chaque
              élève afin qu'il s'épanouisse dans sa future classe.
            </p>
            {/** déroulement  */}
            <h2 className={classes.H2}>Comment se déroulent les cours ?</h2>
            <p>
              Comme tous les cursus chez EVE Éducation, les cours s'effectuent{" "}
              <strong>
                en visio, au sein d'une classe interactive à effectif réduit
              </strong>{" "}
              pour un apprentissage optimal (environ 10 élèves).
              {/*<br />
              En savoir plus sur <a href="/">nos enseignants en primaire</a>.*/}
              <br />
              <strong>
                Les cours ont lieu le matin, du dimanche au jeudi
              </strong>{" "}
              pour un volume de cours journalier d'environ 4 heures.
              <br />À partir du cycle 3,{" "}
              <strong>les classes sont séparées filles/garçons</strong>.
            </p>
            <p>
              Tout au long de l’année, l'élève bénéficie d’un accompagnement
              particulier qui répond à ses besoins.{" "}
              <strong>
                Il bénéficie aussi d’un semainier complet pour lui permettre de
                travailler régulièrement les notions vues en classe
              </strong>
              . Des examens réguliers valident ses compétences.
            </p>
            <p className={classes.ApresParagraphe}>
              À la fin de chaque passage, nous offrons à nos élèves des{" "}
              <strong>certificats de mérite</strong> pour l’effort fourni.
            </p>
            {/** zoom  */}
            <h2 className={classes.H2}>Venez discuter avec nous</h2>
            <p>
              Vous souhaitez en savoir plus et dialoguer avec nous avant de vous
              décider ?
              <br />
              Une permanence sur Zoom est accessible{" "}
              <strong>chaque vendredi matin de 9 h à 11 h</strong>.<br />
              Nous accueillons les familles pour un échange privilégié, par
              ordre d'arrivée.
            </p>
            <a
              href="https://us06web.zoom.us/my/mmeladirectrice"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={classes.BtnZoom}>
                Nous rejoindre sur Zoom
              </button>
            </a>
          </div>
        </div>
        {/** preuve sociale  */}
        <div
          className={classes.PreuveSociale}
          style={{ flexDirection: "column", backgroundColor: _Colors.purple2 }}
          id="temoignages-dc-primaire"
        >
          <h2
            className={classes.H2}
            style={
              appareil === "PC"
                ? { marginBottom: "2em" }
                : { marginBottom: "0" }
            }
          >
            Témoignages de parents d'élèves
          </h2>
          <div className={classes.TemoignagesContainer}>
            <blockquote className={classes.Temoignage}>
              <i
                className="fa-solid fa-quote-left"
                style={{ color: _Colors.purple, fontSize: "2rem" }}
              ></i>
               Voilà ma deuxième année chez vous en double cursus pour ma fille
              qui est actuellement en CE2. Autant vous dire que je suis conquise
              et ma fille encore plus, je ne saurais vous décrire ma
              satisfaction car je ne trouve pas les mots adéquats, mais c’est
              avec beaucoup d’émotion, de satisfaction et d’amour que j’entends
              ma fille et ses camarades réciter les nobles versets, les
              invocations et certains hadiths. J’aime la bienveillance et
              l’amour que les maîtresses donnent aux enfants. Pour le pôle
              classique, totale satisfaction pour Maîtresse F., ma fille est
              très heureuse de l’avoir et dès les premiers jours, ma fille s’est
              attachée à elle, un énorme merci à elle. Concernant la direction
              d'EVE Éducation, elle est incroyable Allahumma baryk.
              Alhamdulillah totale satisfaction pour Eve Education, on espère
              que l’aventure continuera encore longtemps. 
              <i
                className="fa-solid fa-quote-right"
                style={{ color: _Colors.purple, fontSize: "2rem" }}
              ></i>
              <footer>
                — <cite>Maman d'une élève en CE2</cite>
              </footer>
            </blockquote>
            <blockquote className={classes.Temoignage}>
              <i
                className="fa-solid fa-quote-left"
                style={{ color: _Colors.purple, fontSize: "2rem" }}
              ></i>
               Ma fille est pour sa 3ème année chez Eve et en classe de CM1 en
              double cursus et wal hamdoulilah j'en suis satisfaite. Ma fille
              n'a fait qu'évoluer mashaAllah. Le suivi et l'échange avec les
              professeurs est juste top dès que nous avons besoin l'équipe est
              très réactive dans les réponses avec beaucoup de bienveillance et
              de très bons conseils. Les supports sont très bien faits et
              l'équipe est formée pour faire l'apprentissage en ligne Allahouma
              barik avec des classes en petit effectif ce qui permet une
              progression rapide et efficace wal hamdoulilah. Eve vise
              l'excellence et cela se ressent dans les méthodes d'apprentissage
              ainsi que dans l'accompagnement. Je ne regrette pas d'avoir mis
              mes enfants chez Eve et le reste de la fratrie suivra inshaAllah.
              Baraka Allahou fikoum à toute l'équipe de Eve. 
              <i
                className="fa-solid fa-quote-right"
                style={{ color: _Colors.purple, fontSize: "2rem" }}
              ></i>
              <footer>
                — <cite>Maman d'une élève en CM1</cite>
              </footer>
            </blockquote>
          </div>
        </div>

        {/** tarifs  */}
        <h2
          className={classes.H2Tarifs}
          id="tarifs-primaire-double"
          style={{ color: _Colors.purple }}
        >
          LES TARIFS
        </h2>
        <div className={classes.DivTarifs}>
          <div>
            Inscrivez <u>dès maintenant</u> votre enfant pour l'année 2024-2025
          </div>
          <div>
            Aujourd'hui, réglez <span>seulement 139 €</span> sur la totalité
            pour assurer votre place !
          </div>
          <div>Places limitées !</div>
        </div>
        <svg
          className={classes.FlechePromoDouble}
          xmlns="http://www.w3.org/2000/svg"
          width="76"
          height="108"
          viewBox="0 0 76 108"
        >
          <defs>
            <style>{`.a{fill:#F2E0F3;}`}</style>
          </defs>
          <g transform="translate(-922 -6623)">
            <rect
              className="a"
              width="46"
              height="9"
              transform="translate(937 6623)"
            />
            <rect
              className="a"
              width="46"
              height="47"
              transform="translate(937 6642)"
            />
            <path
              className="a"
              d="M38,0,76,49H0Z"
              transform="translate(998 6731) rotate(180)"
            />
          </g>
        </svg>

        {/** promos  */}
        {/** 
        <h2 className={classes.H2Promo} style={{ color: _Colors.purple }}>
          PROMOTION FÉVRIER EXCEPTIONNELLE À SAISIR D'URGENCE !
        </h2>
        <div className={classes.DivPromoDouble}>
          {appareil !== "Mobile" ? (
            <>
              <div>
                <span>Du 1er au 29 février</span>, profitez des meilleurs tarifs
              </div>
              <div>
                avec des <span>frais de dossier</span>{" "}
                <span style={{ fontSize: "28px" }}>gratuits</span>
              </div>
              <div>
                et, en cas de paiement annuel, une <span>remise de</span>{" "}
                <span style={{ fontSize: "28px" }}>-20%</span> sur le tarif
                initial 
                <sup>
                  <i
                    className="fa-solid fa-circle-info"
                    style={{ color: _Colors.purple, cursor: "pointer" }}
                    title="au lieu de la remise de -10% déjà en vigueur"
                  ></i>
                </sup>
              </div>
            </>
          ) : (
            <div>
              <span>Du 1er au 29 février</span>, profitez des meilleurs tarifs
              avec des <span>frais de dossier</span>{" "}
              <span style={{ fontSize: "28px" }}>gratuits</span> et, en cas de
              paiement annuel, une <span>remise de</span>{" "}
              <span style={{ fontSize: "28px" }}>-20%</span> sur le tarif
              initial 
              <sup>
                <i
                  className="fa-solid fa-circle-info"
                  title="au lieu de la remise de -10% déjà en vigueur"
                  style={{ color: _Colors.purple, cursor: "pointer" }}
                ></i>
              </sup>
            </div>
          )}
        </div>*/}
        {/**PROMO 
        <h2 className={classes.H2Promo} style={{ color: _Colors.purple }}>
          PROMOTION RAMADAN EXCEPTIONNELLE À SAISIR D'URGENCE !
        </h2>
        <div className={classes.DivPromoDouble}>
          <span role="img" aria-label="lune">
            &#x1F319;
          </span>{" "}
          <span>
            Du 1<sup>er</sup> au 8 avril, faites <u>encore plus d'économies</u>
             !
          </span>{" "}
          <span role="img" aria-label="lune">
            &#x1F319;
          </span>
          <br />
          <span>- 15%</span> sur le tarif initial
          <sup>
            <i
              className="fa-solid fa-circle-info"
              title="au lieu de la remise de -10% déjà en vigueur"
            ></i>
          </sup>{" "}
          en cas de paiement annuel
          <br />
          <span>- 10%</span> sur le tarif initial
          <sup>
            <i
              className="fa-solid fa-circle-info"
              title="au lieu de la remise de -5% déjà en vigueur"
            ></i>
          </sup>{" "}
          en cas de paiement trimestriel
        </div>
        <button className={classes.BtnPromoDouble} onClick={toggleFormulaire}>
          Je veux obtenir le code promo
        </button>
        <br />*/}

        <ChoixPays togglePays={togglePays} pays={pays} position="tarifs" />
        <br />
        <PricingCards
          cursus="double"
          section={`double-cursus-Primaire`}
          pays={pays}
        ></PricingCards>

        {/** autres offres  */}
        <h2 className={classes.H2Offres}>
          CONSULTEZ NOS AUTRES CURSUS COMPLETS EN LIGNE
        </h2>
        <div className={classes.AutresOffres}>
          <a href="/cursus-classique/primaire">
            <img src={offreCC} alt="miniature offre double cursus"></img>
          </a>
          <a href="/cursus-bilingue/primaire">
            <img src={offreCBI} alt="miniature offre cursus bilingue"></img>
          </a>
          <a href="/cursus-belge/primaire">
            <img src={offreCBE} alt="miniature offre cursus belge"></img>
          </a>
        </div>
      </div>
      {/** garanties  */}
      <div className={classes.Garanties}>
        <h2 className={classes.H2Garanties}>NOS GARANTIES</h2>

        <div>
          {appareil !== "PC" && (
            <img src={clipartGarantie} alt="clipart garantie blanc"></img>
          )}
          <div>
            <h3 className={classes.H3}>EDUQUER ENSEMBLE</h3>
            <p>
              EVE Éducation offre un accompagnement en ligne dédié et guide les
              familles dans l’éducation de leurs enfants pour une instruction
              familiale épanouissante.
              <br />
              Vous n’êtes plus seul !
            </p>
          </div>
          {appareil === "PC" && (
            <img src={clipartGarantie} alt="clipart garantie blanc"></img>
          )}
          <div>
            <h3 className={classes.H3}>VOTRE RÉUSSITE, NOTRE ENGAGEMENT</h3>
            <p>
              Votre objectif : viser la réussite
              <br />
              Notre engagement : vous donnez les moyens de réussir
            </p>
          </div>
        </div>
      </div>
      {/** 
      {modalEdt && (
        <div className={classes.ModalEdt} onClick={toggleModal}>
          <img src={edt} alt="emploi du temps primaire cursus classique"></img>
        </div>
      )}*/}
      {modalForm && (
        <FormCodePromo
          provenance={"Double cursus primaire"}
          setModalForm={setModalForm}
        />
      )}
    </div>
  );
};

export default PrimaireDouble2024;
