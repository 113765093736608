import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PDFBookFlip from "../PDFBookFlip";
import { createUseStyles } from "react-jss";
import { _BoxShadows, _Colors } from "../../../../Jss-styles/_variables";

const useStyles = createUseStyles({
  Link: {
    "& h5": {
      color: _Colors.darkBlue,
      transition: "all 200ms ease-in-out",
      cursor: "pointer",
      lineHeight: "1.5",
      //textAlign: "justify",
      margin: "2rem auto",
      "&:hover": {
        color: _Colors.darkPink,
        transform: "scale(1.05)",
      },
    },
  },
  Modal: {
    minWidth: "min-content !important",
    //maxWidth: "740px !important",
    width: "100% !important",
    height: "min-content !important",
    maxHeight: "100vh !important",
    margin: "auto !important",

    "& .modal-content": {
      //height: "min-content !important",
      maxHeight: "95vh !important",
      maxWidth: "100vw !important",
    },
    "& .modal-body": {
      overflowY: "scroll",
      //width: "min-content",
    },
  },
  Thumbnail: {
    width: "100%",
    cursor: "pointer",
    transition: "all 200ms ease-in-out",
    borderRadius: "5px",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: _BoxShadows.large,
    },
  },
  H2: {},
});

const ModalBook = (props) => {
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const toggle = () => setModal(!modal);

  let ConvertStringToHTML = function (str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");
    return doc.body.innerHTML;
  };

  return (
    <Fragment>
      <div className={classes.Link}>
        {props.tag === "img" ? (
          <img
            src={props.thumbnailImg}
            alt={props.thumbnailDescription}
            onClick={toggle}
            className={classes.Thumbnail}
          />
        ) : (
          <h2
            className={props.style}
            style={{ backgroundColor: props.backgroundColor }}
            onClick={toggle}
            dangerouslySetInnerHTML={{
              __html: ConvertStringToHTML(props.title),
            }}
          ></h2>
        )}
      </div>
      <Modal centered isOpen={modal} toggle={toggle} className={classes.Modal}>
        <ModalHeader toggle={toggle}>
          <span
            dangerouslySetInnerHTML={{
              __html: ConvertStringToHTML(props.title),
            }}
          ></span>
        </ModalHeader>
        <ModalBody>
          <PDFBookFlip src={props.src} numPages={props.numPages} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ModalBook;
