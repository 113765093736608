import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { _Colors } from "../../../../Jss-styles/_variables";

const useStyles = createUseStyles({
  Modal: {
    maxHeight: "100vh ",
    maxWidth: "900px",
    marginTop: "0px",
  },
  ModalBody: {
    padding: "2rem",
    textAlign: "center",
    overflowY: "scroll",
    maxHeight: "100vh",
  },
  ModalHeader: {
    "& span , h5": {
      color: `${_Colors.purple} !important`,
    },
  },
  Title: {
    color: `${_Colors.blue} !important`,
    cursor: "pointer",
    borderStyle: "none",
    fontSize: "1.1rem",
    textAlign: "center",
    backgroundColor: "#fff",
    "&:hover": {
      color: `${_Colors.darkPink} !important`,
    },
  },
  Image: {
    width: "100%",
    height: "100%",
    marginBottom: "2rem",
  },
  Figure: {
    marginBottom: "0",
    "& img": {
      width: "100%",
      maxWidth: "100%",
      display: "block",
    },
    transition: "all 200ms ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
});

const Schedules = ({ schedules = [] }) => {
  const [modal, setModal] = useState({});
  const classes = useStyles();

  const toggle = (e, title) => {
    e.preventDefault();
    setModal({ ...modal, [title]: !modal[title] });
  };

  let ConvertStringToHTML = function (str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");
    return doc.body.innerHTML;
  };

  const getSchedules = () => {
    let title = schedules.title;
    if (modal[title] === undefined) {
      setModal({ ...modal, [title]: false });
    }
    return (
      <>
        {/* Bouton déclencheur de la modal*/}
        <figure
          className={classes.Figure}
          onClick={(e) => toggle(e, title)}
          style={{
            marginInline: "auto",
          }}
        >
          <img src={schedules.button} alt={schedules.alt}></img>
        </figure>

        {/* THE Modal !!!*/}
        <Modal
          isOpen={modal[title]}
          toggle={(e) => toggle(e, title)}
          className={classes.Modal}
        >
          <ModalHeader
            className={classes.ModalHeader}
            toggle={(e) => toggle(e, title)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: ConvertStringToHTML(title),
              }}
            ></span>
          </ModalHeader>
          <ModalBody className={classes.ModalBody}>
            <div>
              {schedules.image.map((img, key) => (
                <img
                  className={classes.Image}
                  key={key}
                  src={img}
                  alt={schedules.alt}
                />
              ))}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  };

  return getSchedules();
};

export default Schedules;
