
class LocalStorage {

    constructor(props) {
        this.productsCartsStorage = JSON.parse(localStorage.getItem('productsCart'))
    }
    
    addProductsToProductsCartStorage (newproduct) {
        
        let productsCartsStorage = JSON.parse(localStorage.getItem('productsCart'))
        if(Array.isArray(productsCartsStorage)) {

            productsCartsStorage.push(newproduct)
            localStorage.setItem("productsCart", JSON.stringify(productsCartsStorage)) 

        } else   
            localStorage.setItem("productsCart",JSON.stringify([newproduct]))
    }    

    removeProductFromProductsCartStorage (productId) {
        let productsCartsStorage = JSON.parse(localStorage.getItem('productsCart'))
        if(Array.isArray(productsCartsStorage)) {
                let newCart = productsCartsStorage.filter(product => product.id !== productId) ;
                if(newCart.length !== 0){
                    localStorage.setItem("productsCart",JSON.stringify(newCart)) ;
                } else {
                    localStorage.removeItem("productsCart")
                }
            
        }

    }

}

export default LocalStorage;