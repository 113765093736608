import React from "react";
import { _Colors } from "../../../../Jss-styles/_variables";

const CodePromo = () => {
  return (
    <div style={{ margin: "1rem", fontSize: "1rem" }}>
      <div
        style={{
          color: _Colors.purple,
          marginBottom: "3rem",
          marginTop: "2rem",
          fontWeight: "bold",
          fontSize: "1.1rem",
        }}
      >
        Félicitations ! Bénéficiez de notre code promotionnel, valable du 1er au
        8 avril
      </div>
      <div>
        <p>Assalamu 'alaykum wa rahmatuLLAHI wa barakatuh</p>
        <p>Cher parent,</p>
        <p>
          Bénéficiez d'un tarif réduit pour toute inscription en{" "}
          <b>Double cursus</b> pour l'année 2024-2025,{" "}
          <b>
            formule <u>trimestrielle</u> ou <u>annuelle</u>
          </b>{" "}
          grâce à ce code promo :
        </p>
        <p
          style={{
            color: _Colors.darkPink,
            marginBlock: "1em",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          CURSUSDB15
        </p>
        <p>
          PROFITEZ VITE de votre réduction sur{" "}
          <a href="https://www.eve-education.com">www.eve-education.com</a> !
        </p>
      </div>
    </div>
  );
};

export default CodePromo;
