import React, { Fragment } from "react";
import { Button } from "reactstrap";

import { useSetRecoilState } from "recoil";
import { openProducstCartModalSelector } from "../../../../recoil-atoms/webSiteAtoms";

import { createUseStyles } from "react-jss";
import { _Colors, _BoxShadows } from "../../../../Jss-styles/_variables";

const useStyles = createUseStyles({
  Button: {
    width: "50%",
    minWidth: "220px",
    transition: "all 200ms ease-in-out ",
    marginBottom: "1rem !important",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: _BoxShadows.medium,
    },
  },
  ButtonToList: {
    extend: "Button",
    backgroundColor: _Colors.darkGreen,
    "&:hover": {
      backgroundColor: _Colors.green,
    },
  },
  ButtonContinue: {
    extend: "Button",
    backgroundColor: _Colors.purple,
    "&:hover": {
      backgroundColor: _Colors.darkPink,
    },
  },
});

const EndModalButtons = ({ toggle }) => {
  const classes = useStyles();

  const setOpenProductsCartModal = useSetRecoilState(
    openProducstCartModalSelector
  );

  const handleCartModal = (e) => {
    e.preventDefault();
    toggle(e);
    setOpenProductsCartModal(true);
  };

  return (
    <Fragment>
      <Button onClick={handleCartModal} className={classes.ButtonToList}>
        Voir mon panier
      </Button>
      <Button onClick={toggle} className={classes.ButtonContinue}>
        Continuer mes inscriptions
      </Button>
    </Fragment>
  );
};

export default EndModalButtons;
