import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { /*SyncLoader*/ BounceLoader } from "react-spinners";

const LoadingFallback = () => {
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colors = ["#FF6FBC", "#4E5AB1", "#4CC8E9"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 1000); // Change color every second

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${colors[currentColorIndex]};
  `;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <BounceLoader
        color={colors[currentColorIndex]}
        css={override}
        size={200}
      />
    </div>
  );
};

export default LoadingFallback;
